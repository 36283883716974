import styled from 'styled-components';
import PropTypes from 'prop-types';
import SearchInput from '../shared/searchInput';

const SearchContainer = styled.div`
  background-image: url('/img/breeder-bandeau-interieur.jpg');
  display: flex;
  padding: 46px 0 40px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  justify-content: center;
  height: 523px;

  .content {
    margin-top: 73px;
    max-width: 1365px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .search__title {
    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    margin-left: 50px;
  }

  .search__subtitle {
    font-size: 26px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: white;
    margin-left: 50px;
    margin-bottom: 32px;
    span {
      color: #c7d46a;
    }
  }

  .searchBar {
    margin-left: 30px;
    margin-top: -10px;
  }
`;

const Search = ({ withSearchBar }) => {
  let input = '';
  if (withSearchBar) input = <SearchInput />;
  return (
    <SearchContainer>
      <div className="content">
        <h1 className="search__title">Votre partenaire digital</h1>

        <div className="search__subtitle">
          <span>pour s&apos;informer</span>, <span>se former</span>, <span>échanger et bâtir</span> l&apos;agriculture
          de demain
        </div>
        <div className="searchBar">{input}</div>
      </div>
    </SearchContainer>
  );
};

Search.propTypes = {
  withSearchBar: PropTypes.bool
};

Search.defaultProps = {
  withSearchBar: true
};

export default Search;
