import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { canVote, registerVote, addProposition } from '../../queries/backendQueries';
import ImpossibleAction from '../shared/impossibleAction';
import { Form, ProgressBar, Alert } from 'react-bootstrap';
import Main from '../buttons/main';

const SurveyContainer = styled.div`
  .container {
    max-width: 1140px;
    height: 398px;
    margin: 88px 0;
    background: url('/img/vachepre.jpg') #f4f4f4;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
    display: flex;
    align-items: center;
  }

  .title {
    width: 263px;
    font-size: 34px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.12;
    letter-spacing: normal;
    text-align: left;
    color: #212529;
    margin-left: 97px;
    margin-right: 30px;
  }

  .title::after {
    content: url('/icons/icon-connect-right.svg');
    margin-left: 4px;
    vertical-align: text-top;
  }

  .content {
    max-width: 361px;
    width: 100%;
    height: 302px;
    padding: 26px 24px 24px;
    border-radius: 20px;
    box-shadow: 0 5px 16px 0 rgba(33, 37, 41, 0.1);
    background-color: #ffffff;
    overflow: auto;
  }

  h4 {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
    color: #212529;
  }

  ul {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding-left: 0;
  }

  li {
    list-style: none;
    margin-right: 1rem;
    position: relative;
  }

  label,
  .label {
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: left;
    color: #5d6267;
    cursor: pointer;
  }

  input[type='text'] {
    border-radius: 8px;
    border: solid 1px #5d6267;
    padding: 14px 16px 3px;
    background-color: #ffffff;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: left;
    color: #5d6267;
  }

  input:hover {
    border: solid 1px #4e5fb7;
  }

  input[type='radio'] {
    cursor: pointer;
  }

  .alert {
    margin-top: -40px;
    margin-bottom: 5px;
    padding: 5px 1rem;
    position: absolute;
    width: 100%;
    z-index: 20;
  }

  .newProposition {
    display: flex;
  }

  .total {
    margin-top: 2px;
    background-color: #f4f4f4;
  }

  .progress-bar {
    border-radius: 4px;
    background-color: #4e5fb7;
  }

  .percent {
    text-align: end;
    font-size: 13px;
  }

  @media all and (max-width: 838px) {
    .container {
      flex-direction: column;
      background-size: cover;
    }

    .title {
      margin-bottom: 60px;
      margin-top: -100px;
    }
  }
`;

const Survey = ({ user, survey, totalVotes }) => {
  const [hasVoted, setHasVoted] = useState(false);
  const [message, setMessage] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [newProposition, setNewProposition] = useState('');
  const [votes, setVotes] = useState([]);

  useEffect(() => {
    if (user && survey) {
      canVote(user.idSso, survey.idSurvey)
        .then((answer) => {
          setHasVoted(answer.data[0]);
          setVotes(answer.data);
        })
        .catch((err) => console.log(err));
    } else {
      setHasVoted(false);
    }
  }, [user, survey, message]);

  function handleClose(event) {
    if (event) event.stopPropagation();
    setModalShow(false);
  }

  function addNewProposition() {
    setShowInput(false);
    if (!user) {
      setModalShow(true);
      return;
    }
    if (user.userStatus === 'CREATED') {
      setIsConnected(true);
      setModalShow(true);
      return;
    }
    addProposition(user.idSso, survey.idSurvey, newProposition)
      .then(() => {
        setMessage('Votre proposition a bien été enregistrée.');
        setNewProposition(null);
      })
      .catch(() => setMessage('Une erreur est survenue'))
      .finally(() => {
        setTimeout(() => {
          setMessage('');
        }, 5000);
      });
  }

  function vote(e, idProposition) {
    e.stopPropagation();
    if (!user) {
      setModalShow(true);
      return;
    }
    if (user.userStatus === 'CREATED') {
      setIsConnected(true);
      setModalShow(true);
      return;
    }
    registerVote(user.idSso, idProposition, survey.isClosed)
      .then((result) => {
        if (result.success) setMessage('Votre vote a bien été pris en compte.');
        else setMessage('Une erreur est survenue');
      })
      .catch((err) =>
        setMessage(
          err.response.data.error === 'USER_ALREADY_VOTED' || err.response.data.error.message?.includes('duplicate key')
            ? 'Désolé mais il semblerait que vous ayez déjà voté pour ce sondage.'
            : 'Une erreur est survenue'
        )
      )
      .finally(() => {
        setTimeout(() => {
          setMessage('');
        }, 5000);
      });
  }

  return (
    <SurveyContainer>
      <div className="container">
        <h2 className="title">Votre avis nous intéresse</h2>
        <div style={{ position: 'relative' }}>
          <Alert hidden={!message} className="alert" variant="warning" transition>
            {message}
          </Alert>
          <div className="content" hidden={!survey}>
            <h4>{survey?.question}</h4>
            <p>{survey?.description}</p>
            <ul>
              {survey?.propositions.map((prop, i) => {
                if (!hasVoted) {
                  return (
                    <li key={i}>
                      <Form.Check
                        type="radio"
                        id={prop.idProposition}
                        label={prop.propositionText}
                        onClick={(e) => vote(e, prop.idProposition)}
                      />
                    </li>
                  );
                } else {
                  if (!survey.isClosed) {
                    return (
                      <li key={i}>
                        <Form.Check
                          type="radio"
                          id={prop.idProposition}
                          label={prop.propositionText}
                          checked={votes.includes(prop.idProposition)}
                          onClick={(e) => vote(e, prop.idProposition)}
                        />
                        <ProgressBar className="total" now={Math.round((prop.nbVotes / totalVotes) * 100)} />
                        <div className="percent">
                          {Math.round((prop.nbVotes / totalVotes) * 100)} {'%'}
                        </div>
                      </li>
                    );
                  } else {
                    return (
                      <li key={i}>
                        <div className="label">{prop.propositionText}</div>
                        <ProgressBar className="total" now={Math.round((prop.nbVotes / totalVotes) * 100)} />
                        <div className="percent">
                          {Math.round((prop.nbVotes / totalVotes) * 100)} {'%'}
                        </div>
                      </li>
                    );
                  }
                }
              })}
            </ul>
            <div hidden={survey?.isClosed || showInput}>
              <Main text="Ajouter une proposition" onButtonClick={() => setShowInput(true)} style={{ margin: '0' }} />
            </div>

            <div className="newProposition" hidden={!showInput}>
              <input
                type="text"
                value={newProposition}
                placeholder="Saisir votre proposition"
                onChange={(e) => setNewProposition(e.target.value)}
              />
              <Main text="Ajouter" onButtonClick={addNewProposition} style={{ margin: '0 20px', minWidth: '130px' }} />
            </div>
          </div>
        </div>
      </div>
      <ImpossibleAction show={modalShow} handleClose={handleClose} isConnected={isConnected} />
    </SurveyContainer>
  );
};

Survey.propTypes = {
  user: PropTypes.object,
  survey: PropTypes.object,
  totalVotes: PropTypes.number
};

export default Survey;
