export const StorageKey = {
  ACCES_TOKEN: 'ACCES_TOKEN'
};

export const Email = {
  CONTACT: process.env.NEXT_PUBLIC_CONTACT_EMAIL
};

export const API_KEY = {
  GOOGLE: process.env.NEXT_PUBLIC_GOOGLE_API_KEY
};

export const ExistingServices = ['strategiederenouvellement'];
