import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Main from '../buttons/main';

const StyleModal = styled(Modal)`
  .modal-dialog {
    max-width: 600px;
  }

  .modal-content {
    border-radius: 20px;
  }

  .modal-header {
    border: none;
    padding-bottom: 0;
  }

  .modal-footer {
    border: none;
    padding-top: 0;
  }

  .modal-title {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
    color: #212529;
    margin-left: 8px;
  }

  p {
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: left;
    color: #5d6267;
  }

  .btn-close {
    width: 30px;
    height: 30px;
    padding: 0;
    border-radius: 4px;
    background-color: #4e5fb7;
    opacity: 1;
    background-image: url('/icons/cross.svg');
    background-size: 25px 25px;
    margin: 0;
  }
`;

const SubscriptionRequest = ({ show, handleClose, status }) => {
  return (
    <StyleModal
      show={show}
      onHide={handleClose}
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Demande envoyée</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {status === 'EN_ATTENTE' ? (
          <p>
            Votre demande d&apos;adhésion à la communauté a été envoyée à l&apos;adiminstrateur. Si votre demande est
            acceptée, vous pourrez rejoindre la communauté.
          </p>
        ) : (
          <p>
            Vous avez déjà soumis une demande d&apos;adhésion à cette communauté et votre demande a été rejetté par
            l&apos;administrateur. Vous ne pouvez plus rejoindre cette communauté.
          </p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Main text="OK" onButtonClick={handleClose} style={{ minWidth: '113px' }} />
      </Modal.Footer>
    </StyleModal>
  );
};

SubscriptionRequest.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  status: PropTypes.string
};

export default SubscriptionRequest;
