import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Resource from '../preview/resource';
import Main from '../buttons/main';

const ListContainer = styled.div`
  text-align: center;

  h2 {
    font-size: 34px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.12;
    letter-spacing: normal;
    color: #212529;
  }

  h2::before {
    content: url('/icons/icon-connect-left.svg');
    margin-right: 4px;
    vertical-align: text-top;
  }

  h2::after {
    content: url('/icons/icon-connect-right.svg');
    margin-left: 4px;
    vertical-align: text-top;
  }

  .top {
    font-size: 30px;
    line-height: 1.2;
    text-align: center;
    margin-top: 20px;
  }

  .list {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
`;

const NewsList = ({ news }) => {
  return (
    <ListContainer>
      <div className="top">
        <h2>Actualités</h2>
      </div>
      <div className="list">
        {news.map((article) => (
          <Resource key={article.idResource} resource={article} />
        ))}
      </div>
      <Main url="/article/all" text="Toutes les actualités" />
    </ListContainer>
  );
};

NewsList.propTypes = {
  news: PropTypes.array
};

export default NewsList;
