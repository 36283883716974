import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { useRouter } from 'next/router';
import { getImageUrl } from '../../tools/utils';
import { AcademicCapIcon } from '@heroicons/react/outline';
import ResourceBtn from '../buttons/resource';
import { getResourceSlug } from '../../tools/resources';

const StyleCard = styled(Card)`
  max-width: 263px;
  width: 100%;
  min-height: 454px;
  margin: 20px 10px;
  border-radius: 20px;
  box-shadow: 0 5px 16px 0 rgba(33, 37, 41, 0.1);
  background-color: #ffffff;
  border: none;
  cursor: pointer;

  .imgPreview {
    max-width: 263px;
    height: 160px;
    object-fit: cover;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .articleTitle {
    margin: 24px 0;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
    color: #4e5fb7;
  }

  .body {
    position: relative;
  }

  .card-body {
    border-top: 4px solid #4e5fb7;
  }

  .icon {
    width: 20px;
    height: 20px;
    position: absolute;
    color: #ffffff;
    top: -13px;
    left: 33px;
    z-index: 2;
    opacity: 0.7;
  }

  .cat {
    position: absolute;
    top: -18px;
    right: 0;
    height: 31px;
    width: 228px;
    background-color: #4e5fb7;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.38;
    color: #fff;
    padding-left: 40px;
    padding-top: 4px;
    color: rgba(255, 255, 255, 0.7);
  }

  .bg {
    width: 39px;
    height: 42px;
    position: absolute;
    top: -25px;
    left: 24px;
    z-index: 1;
    border-radius: 50%;
  }

  .description {
    margin: 24px 0;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: left;
    color: #5d6267;
  }

  .btnContainer {
    display: flex;
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 1.25rem;
    margin-bottom: 15px;
  }

  .more {
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #5d6267;
    margin-right: 37px;
  }

  .gradient {
    background-image: linear-gradient(to bottom, #4e5fb7, #3d4da0);
  }

  p:after {
    content: '';
    display: inline-block;
    width: 0px;
  }

  p {
    margin-bottom: 0px;
  }
`;

const Service = ({ service }) => {
  const router = useRouter();

  const [imgUrl, setImgUrl] = useState(null);

  getImageUrl(service.image).then((result) => setImgUrl(result));

  return (
    <StyleCard
      onClick={(e) => {
        e.stopPropagation();
        if (service.hasPage) {
          service.resourceType
            ? router.push({
                pathname: `/${service.resourceType.toLowerCase()}/${getResourceSlug(service)}`
              })
            : service.idFormation
            ? router.push({
                pathname: `/service/formation/${service.slug}`
              })
            : router.push({
                pathname: `/service/${service.slug}`
              });
        } else if (service.url) {
          router.push({
            pathname: service.url
          });
        }
      }}
    >
      <Card.Img className="imgPreview" variant="top" src={imgUrl} />
      <Card.Body className="body">
        <AcademicCapIcon className="icon" />
        <img className="bg" src="/icons/bg-icon-service.svg" alt="bg" />
        <div className="cat">{service.categories ? service.categories[0] : ''}</div>
        <Card.Title className="articleTitle">{service.label}</Card.Title>
        <Card.Text className="description display-linebreak">
          {service.serviceDescription
            ? service.serviceDescription.split('\\n').map((str) => {
                return str + '\n';
              })
            : ''}
        </Card.Text>
        {service.hasPage || service.url ? (
          <div className="btnContainer">
            <ResourceBtn resource={service} />
          </div>
        ) : (
          ''
        )}
      </Card.Body>
    </StyleCard>
  );
};

Service.propTypes = {
  service: PropTypes.object
};

export default Service;
