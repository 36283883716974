import Head from 'next/head';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import styled from 'styled-components';
import CommunityList from '../components/home/community-list';
import ContactForm from '../components/home/contact-form';
import Newsletter from '../components/home/newsletter';
import Survey from '../components/home/survey';
import Footer from '../components/home/footer';
import Header from '../components/home/header';
import NewsList from '../components/home/news-list';
import OadList from '../components/home/oad-list';
import Search from '../components/home/search';
import Tile from '../components/home/tile';
import Main from '../components/buttons/main';
import { getResourcesPreview, getSurvey, getFolders, getServicesHomePage } from '../queries/backendQueries';
import { UserContext } from './_app';
import { url, revalidate } from '../config/config';
import { isSubscribed, sortFolders } from '../tools/utils';
import Service from '../components/preview/service';

const Container = styled.div`
  .container-fluid {
    padding: 0 20px 50px;
    max-width: 1365px;
  }

  [class^='col-'] {
    margin-top: 2vh;
    margin-bottom: 1vh;
  }

  .container-fluid > .row > [class^='col-']:first-child {
    margin-top: 2vh;
  }

  .container-fluid > .row > [class^='col-']:first-child [class^='col-']:first-child {
    margin-top: 0;
  }

  hr {
    margin-top: 30px;
    margin-bottom: 0;
  }

  .forme {
    height: 64px;
    width: 128px;
  }

  .commList {
    min-width: 290px;
  }

  .mobile-displayed {
    display: none !important;
  }

  .tiles {
    display: flex;
    justify-content: space-evenly;
    margin-top: -500px;
    flex-wrap: wrap;
  }

  .position {
    position: relative;
    z-index: 4;
  }

  .services {
    background-image: url('/img/breeder-bandeau-services.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    position: absolute;
    left: 15px;
    right: 15px;
    padding-left: calc((100% - 1280px) / 2);
    padding-right: calc((100% - 1280px) / 2);
  }

  .serviceTitle {
    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
  }

  .serviceTitle::after {
    content: url('/icons/icon-connect-right-white.svg');
    margin-left: 4px;
    vertical-align: text-top;
  }

  h1::before {
    content: url('/icons/icon-connect-left.svg');
    margin-right: 4px;
    vertical-align: text-top;
  }

  h1::after {
    content: url('/icons/icon-connect-right.svg');
    margin-left: 4px;
    vertical-align: text-top;
  }

  .desc {
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
  }

  .centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .tileList {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }

  .news {
    margin-top: 680px;
  }

  /* Mobile */
  @media all and (max-width: 1400px) {
    .services {
      position: unset;
      margin-left: -20px;
      margin-right: -20px;
    }

    .news {
      margin-top: 20px;
    }
  }

  @media all and (max-width: 1197px) {
    .tiles {
      margin-top: -800px;
    }
  }

  @media all and (max-width: 816px) {
    .tiles {
      margin-top: -1150px;
    }
  }
`;

const Elem = styled.div`
  height: 300px;
  background-color: #f4f4f4;

  @media all and (max-width: 1197px) {
    height: 600px;
  }

  @media all and (max-width: 816px) {
    height: 950px;
  }

  @media all and (max-width: 783px) {
    height: 1000px;
  }

  @media all and (max-width: 483px) {
    height: 1050px;
  }

  @media all and (max-width: 320px) {
    height: 1100px;
  }
`;

const Home = ({ resources, folders, survey, totalVotes, servicesHome }) => {
  const { user } = useContext(UserContext);

  const communities = resources.data.filter((resource) => {
    if (resource.resourceType !== 'CHANNEL') {
      return false;
    }
    if (resource.channelStatus === 'HIDDEN') {
      return isSubscribed(user, resource);
    }
    return true;
  });
  const oads = resources.data.filter((resource) => resource.resourceType === 'OAD');
  const news = resources.data.filter((resource) => resource.resourceType === 'ARTICLE').slice(0, 4);
  servicesHome = servicesHome.slice(0, 2);

  const form = user?.idSso ? <ContactForm /> : <Newsletter />;

  return (
    <div>
      <Head>
        <title>Breeder Connect&apos; • Le partenaire digital des agriculteurs - Site d&apos;information agricole</title>
        <link rel="canonical" href={`${url.connect}`} />
        <meta
          name="description"
          content="L'intelligence collective au service des Agriculteurs ✓ Des communautés de discussion entre pairs ✓ Des outils de pilotage et d'aide à la décision ✓ Des actualités et conseils concrets"
        />
        <meta name="keywords" content="site information agricole" />
      </Head>

      <Header folders={folders} />

      <main>
        <Search />
        <Elem />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img className="forme" src="/img/forme-multiple-connect.svg" alt="forme" />
        </div>
        <Container>
          <div className="container-fluid">
            <div className="row position">
              <div className="col">
                <div className="tiles">
                  <Tile type="CHANNEL" />
                  <Tile type="OAD" />
                  <Tile type="ARTICLE" />
                </div>
                <div className="col-sm-12" style={{ display: 'flex', justifyContent: 'center' }}>
                  <Main text="Découvrir Breeder Connect" url="/who" style={{ width: '230px' }} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <CommunityList communities={communities.slice(0, 2)} />
              </div>

              <div className="col-sm-6">
                <OadList oads={oads.slice(0, 2)} />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <Survey user={user} survey={survey} totalVotes={totalVotes} />
              </div>
            </div>

            <div className="row services">
              <div className="col-sm-6 centered">
                <h2 className="serviceTitle">Breeder connect c&apos;est aussi</h2>
                <p className="desc">
                  Des solutions pour vous aider dans la gestion de votre exploitation !<br />
                  Accédez à des services performants et innovants délivrés par des partenaires de confiance.
                </p>
                <Main
                  url="/service/all"
                  text="Tous les services de Breeder Connect"
                  style={{ width: '287px', marginLeft: '0px' }}
                />
              </div>
              <div className="col-sm-6 tileList">
                {servicesHome.map((service) => (
                  <Service key={service.idService} service={service} />
                ))}
              </div>
            </div>

            <div className="row news">
              <div className="col-sm">
                <NewsList news={news} />
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col-sm">{form}</div>
            </div>
          </div>
        </Container>
      </main>

      <Footer />
    </div>
  );
};

export default Home;

Home.propTypes = {
  resources: PropTypes.object,
  user: PropTypes.object,
  folders: PropTypes.array,
  survey: PropTypes.object,
  totalVotes: PropTypes.number,
  servicesHome: PropTypes.array
};

const retrieveResourcesList = async () => {
  const resources = { data: [], error: false };

  try {
    const result = await getResourcesPreview();
    resources.data = result.data;
  } catch (e) {
    console.log('Fail to retrieve resources', e);
    resources.error = true;
  }

  return resources;
};

export async function getStaticProps() {
  const survey = getSurvey();
  return {
    props: {
      resources: await retrieveResourcesList(),
      folders: await getFolders()
        .then((response) => (response.success ? sortFolders(response.data) : []))
        .catch((err) => {
          console.log(err);
          return [];
        }),
      survey: await survey
        .then((result) => result.data[0])
        .catch((err) => {
          console.log(err);
        }),
      totalVotes: await survey
        .then((result) => result.data[0].propositions.reduce((sum, val) => sum + val.nbVotes, 0))
        .catch((err) => {
          console.log(err);
        }),
      servicesHome: await getServicesHomePage()
        .then((response) => response.data)
        .catch((err) => {
          console.log(err.response);
          return [];
        })
    },
    revalidate
  };
}
