import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Alert } from 'react-bootstrap';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { API_KEY, Email } from '../constants/appConstants';
import { verifyToken, sendEmail } from '../../queries/backendQueries';
import Main from '../buttons/main';

const NewsletterContainer = styled.div`
  .container {
    text-align: center;
  }

  h2 {
    font-size: 34px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.12;
    letter-spacing: normal;
    color: #212529;
    margin-bottom: 2vw;
  }

  h2::before {
    content: url('/icons/icon-connect-left.svg');
    margin-right: 4px;
    vertical-align: text-top;
  }

  h2::after {
    content: url('/icons/icon-connect-right.svg');
    margin-left: 4px;
    vertical-align: text-top;
  }

  .inner-container {
    max-width: 800px;
    margin: auto;
  }

  input {
    height: calc(2.2em + 0.75rem + 8px);
    border-radius: 8px;
    border: solid 1px #5d6267;
    background-color: #ffffff;
    font-size: 15px;
    line-height: 1.47;
    color: #5d6267;
  }

  input:hover {
    border: solid 1px #4e5fb7;
  }

  .valid {
    border-color: #4e5fb7;
    color: #4e5fb7;
  }

  .invalid {
    border-color: #d92613;
    color: #d92613;
  }

  .action-bar {
    justify-content: center;
  }

  .spinner-border {
    margin-left: 10px;
  }

  .errorContainer {
    text-align: left;
  }

  .error {
    color: red;
    font-size: small;
    position: absolute;
    margin-top: -17px;
    margin-left: 20px;
  }
`;

const Newsletter = () => {
  const [email, setEmail] = useState('');
  const [alert, setAlert] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [captchaValid, setCaptchaValid] = useState(false);

  function handleEmailChange(event) {
    setEmail(event.target.value);
  }

  function verifyEmail() {
    // eslint-disable-next-line no-useless-escape
    if (!email.match(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/)) {
      setError("L'email n'est pas au bon format");
    } else {
      setError('');
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (captchaValid) {
      setLoading(true);
      sendEmail(Email.CONTACT, "Un utilisateur souhaite s'inscrire à la newsletter", 'Newsletter', email)
        .then(() => {
          setAlert(<Alert variant="success">Abonné !</Alert>);
        })
        .catch(() => {
          setAlert(<Alert variant="danger">Une erreur est survenue. Merci de réessayer plus tard.</Alert>);
        })
        .finally(() => {
          setTimeout(() => {
            setAlert('');
          }, 4000);
          setEmail('');
          setLoading(false);
        });
    } else {
      setAlert(<Alert variant="danger">Vous ne pouvez pas vous abonner pour le moment.</Alert>);
    }
  }

  function handleVerify(token) {
    if (!captchaVerified) {
      setCaptchaVerified(true);
      verifyToken(token)
        .then((response) => {
          response.success ? setCaptchaValid(true) : console.log('Erreur de recaptcha');
        })
        .catch((err) => console.log(err));
    }
  }

  const ReCaptchaComponent = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    // Create an event handler so you can call the verification on button click event or form submit
    const handleReCaptchaVerify = useCallback(async () => {
      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');
        return;
      }

      const token = await executeRecaptcha();
      handleVerify(token);
    }, []);

    // You can use useEffect to trigger the verification as soon as the component being loaded
    useEffect(() => {
      handleReCaptchaVerify();
    }, [handleReCaptchaVerify]);

    return <div />;
  };

  return (
    <GoogleReCaptchaProvider reCaptchaKey={API_KEY.GOOGLE}>
      <NewsletterContainer>
        <div className="container">
          <div className="inner-container">
            <div className="top">
              <h2>Recevez gratuitement la newsletter Breeder Connect&apos;</h2>
            </div>

            <div className="content">
              {alert}
              <form>
                <div className="form-group errorContainer">
                  <span className="error">{error}</span>
                  <input
                    type="email"
                    name="mails"
                    className={`form-control${error ? ' invalid' : ' valid'}`}
                    placeholder="Mail"
                    value={email}
                    onChange={handleEmailChange}
                    onBlur={verifyEmail}
                  />
                </div>

                <div className="row action-bar">
                  <ReCaptchaComponent />
                  <div className="col button-container">
                    <Main
                      onButtonClick={handleSubmit}
                      text="Je m'inscris"
                      spinner={loading}
                      disabled={loading || error !== ''}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </NewsletterContainer>
    </GoogleReCaptchaProvider>
  );
};

export default Newsletter;
