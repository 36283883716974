import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Resource from '../preview/resource';
import Main from '../buttons/main';

const ListContainer = styled.div`
  text-align: center;

  .top {
    text-align: center;
  }

  h2 {
    font-size: 34px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.12;
    letter-spacing: normal;
    color: #212529;
  }

  h2::before {
    content: url('/icons/icon-connect-left.svg');
    margin-right: 4px;
    vertical-align: text-top;
  }

  h2::after {
    content: url('/icons/icon-connect-right.svg');
    margin-left: 4px;
    vertical-align: text-top;
  }

  .list {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
`;

const OadList = ({ oads }) => {
  return (
    <ListContainer>
      <div className="top">
        <h2>Aides à la décision</h2>
      </div>
      <div className="list">
        {oads.map((oad) => (
          <Resource key={oad.idResource} resource={oad} />
        ))}
      </div>
      <Main url="/oad/all" text="Toutes les aides à la décision" />
    </ListContainer>
  );
};

OadList.propTypes = {
  oads: PropTypes.array
};

export default OadList;
