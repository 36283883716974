import styled from 'styled-components';
import PropTypes from 'prop-types';
import { NewspaperIcon, ChatAlt2Icon, ChartBarIcon } from '@heroicons/react/outline';

const TileContainer = styled.div`
  max-width: 360px;
  min-height: 206px;
  margin: 29px 10px;
  padding: 40px 40px 39px;
  border-radius: 10px;
  box-shadow: 0 5px 16px 0 rgba(33, 37, 41, 0.1);
  background-color: #ffffff;
  position: relative;

  h2 {
    margin: 0;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
    color: #212529;
  }

  h3 {
    margin: 0 0 16px;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
    color: #212529;
  }

  p {
    margin: 16px 0 0;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: left;
    color: #5d6267;
  }

  .icon {
    width: 20px;
    height: 20px;
    position: absolute;
    color: #586118;
    top: -10px;
  }

  .bg {
    width: 55px;
    height: 58px;
    position: absolute;
    top: -30px;
    left: 24px;
  }
`;

const Tile = ({ type, obj }) => {
  let icon = obj?.icon || '';
  let title = obj?.title || '';
  let subTitle = obj?.subTitle || '';
  let desc = obj?.desc || '';
  switch (type) {
    case 'CHANNEL':
      title = 'Des communautés';
      subTitle = 'de discussion entre pairs';
      icon = <ChatAlt2Icon className="icon" />;
      desc =
        'Agriculteurs, choisissez votre communauté pour échanger des idées, partager vos expériences et avancer efficacement. Posez vos questions, partagez vos observations : agriculteurs et experts vous répondent en toute simplicité et convivialité.';
      break;
    case 'ARTICLE':
      title = 'Des actualités';
      subTitle = 'et conseils concrets';
      icon = <NewspaperIcon className="icon" />;
      desc =
        'Ne manquez plus aucune actualité ! Nutrition animale, repro, conversion Bio, innovations technologiques… retrouvez tous les conseils de nos experts pour vous aider dans votre quotidien de chef d’exploitation.';
      break;
    case 'OAD':
      title = 'Des outils';
      subTitle = 'de pilotage et d’aide à la décision';
      icon = <ChartBarIcon className="icon" />;
      desc =
        'Optimisez vos prises de décisions et gagnez du temps en vous appuyant sur des outils simples et efficaces. Analysez vos données en toute simplicité et simulez les impacts d’un changement sur vos résultats d’exploitation sur différentes thématiques.';
      break;
    default:
      break;
  }

  return (
    <TileContainer className="tile-container">
      <img className="bg" src="/icons/bg-icon.svg" alt="bg" />
      {icon}
      <h2>{title}</h2>
      <h3>{subTitle}</h3>
      <p className="display-linebreak">{desc}</p>
    </TileContainer>
  );
};

Tile.propTypes = {
  type: PropTypes.string,
  obj: PropTypes.object
};

export default Tile;
