import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const LinkContainer = styled.div`
  margin: 0 5px 0 0;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  cursor: pointer;
  color: #4e5fb7;

  &::after {
    content: url('/icons/icon-connect-right-violet.svg');
    margin-left: 4px;
    vertical-align: text-top;
  }

  &:hover::after {
    content: url('/icons/icon-connect-right.svg');
  }

  a:hover,
  &:hover {
    color: #212529;
  }
`;

const PaymentLink = ({ onClick }) => {
  return (
    <LinkContainer>
      <a
        onClick={(e) => {
          e.stopPropagation();
          onClick();
        }}
      >
        En savoir plus
      </a>
    </LinkContainer>
  );
};

PaymentLink.propTypes = {
  onClick: PropTypes.func
};

export default PaymentLink;
