import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { sendEmail, verifyToken } from '../../queries/backendQueries';
import { Alert } from 'react-bootstrap';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { API_KEY } from '../constants/appConstants';
import Main from '../buttons/main';

const Container = styled.div`
  .container {
    text-align: center;
  }

  h2 {
    font-size: 34px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.12;
    letter-spacing: normal;
    color: #212529;
    margin-bottom: 2vw;
  }

  h2::before {
    content: url('/icons/icon-connect-left.svg');
    margin-right: 4px;
    vertical-align: text-top;
  }

  h2::after {
    content: url('/icons/icon-connect-right.svg');
    margin-left: 4px;
    vertical-align: text-top;
  }

  .description {
    margin-bottom: 2vw;
    padding: 0 3vw;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    color: #5d6267;
  }

  .inner-container {
    max-width: 750px;
    margin: auto;
  }

  input {
    height: calc(2.2em + 0.75rem + 8px);
    border-radius: 8px;
    border: solid 1px #5d6267;
    background-color: #ffffff;
    font-size: 15px;
    line-height: 1.47;
    color: #5d6267;
  }

  textarea {
    border-radius: 8px;
    border: solid 1px #4e5fb7;
  }

  input:hover {
    border: solid 1px #4e5fb7;
  }

  .valid {
    border-color: #4e5fb7;
    color: #4e5fb7;
  }

  .invalid {
    border-color: #d92613;
    color: #d92613;
  }

  .action-bar {
    justify-content: center;
  }

  .spinner-border {
    margin-left: 10px;
  }

  .errorContainer {
    text-align: left;
  }

  .error {
    color: red;
    font-size: small;
    position: absolute;
    margin-top: -17px;
    margin-left: 20px;
  }
`;

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      email: '',
      alert: '',
      loading: false,
      captchaVerified: false,
      captchaValid: false,
      error: ''
    };

    this.handleMessageChange = this.handleMessageChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleVerify = this.handleVerify.bind(this);
    this.verifyEmail = this.verifyEmail.bind(this);
  }

  handleMessageChange(event) {
    this.setState({
      message: event.target.value
    });
  }

  handleEmailChange(event) {
    this.setState({ email: event.target.value });
  }

  verifyEmail() {
    // eslint-disable-next-line no-useless-escape
    if (!this.state.email.match(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/)) {
      this.setState({ error: "L'email n'est pas au bon format" });
    } else {
      this.setState({ error: '' });
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.state.captchaValid) {
      this.setState({ loading: true });
      sendEmail(this.state.email, 'Invitation', this.state.message)
        .then(() => {
          this.setState({
            loading: false,
            alert: <Alert variant="success">Le message a bien été envoyé !</Alert>,
            message: '',
            email: ''
          });
          setTimeout(() => {
            this.setState({ alert: '' });
          }, 4000);
        })
        .catch(() =>
          this.setState({
            loading: false,
            alert: <Alert variant="danger">Une erreur est survenue. Merci de réessayer plus tard.</Alert>
          })
        );
    } else {
      this.setState({
        alert: <Alert variant="danger">Vous ne pouvez pas envoyer de mail pour le moment.</Alert>
      });
    }
  }

  handleVerify(token) {
    if (!this.state.captchaVerified) {
      this.setState({ captchaVerified: true });
      verifyToken(token)
        .then((response) => {
          response.success ? this.setState({ captchaValid: true }) : console.log('Erreur de recaptcha');
        })
        .catch((err) => console.log(err));
    }
  }

  render() {
    const ReCaptchaComponent = () => {
      const { executeRecaptcha } = useGoogleReCaptcha();
      // Create an event handler so you can call the verification on button click event or form submit
      const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
          console.log('Execute recaptcha not yet available');
          return;
        }

        const token = await executeRecaptcha();
        this.handleVerify(token);
      }, []);

      // You can use useEffect to trigger the verification as soon as the component being loaded
      useEffect(() => {
        handleReCaptchaVerify();
      }, [handleReCaptchaVerify]);

      return <div />;
    };

    return (
      <GoogleReCaptchaProvider reCaptchaKey={API_KEY.GOOGLE}>
        <Container>
          <div className="container">
            <div className="inner-container">
              <div className="top">
                <h2>Faire découvrir Breeder Connect&apos;</h2>

                <div className="description">
                  <p>
                    Breeder Connect’ vous a plu ? <br />
                    Invitez vos amis à vous rejoindre sur la plateforme Breeder Connect ! 😀
                  </p>
                </div>
              </div>

              <div className="content">
                {this.state.alert}
                <form>
                  <div className="form-group">
                    <textarea
                      rows="3"
                      name="message"
                      className="form-control"
                      placeholder="Message"
                      value={this.state.message}
                      onChange={this.handleMessageChange}
                      required
                    />
                  </div>

                  <div className="form-group errorContainer">
                    <span className="error">{this.state.error}</span>
                    <input
                      type="email"
                      name="mails"
                      className={`form-control${this.state.error ? ' invalid' : ' valid'}`}
                      placeholder="Mail"
                      value={this.state.email}
                      onChange={this.handleEmailChange}
                      onBlur={this.verifyEmail}
                      required
                    />
                  </div>

                  <div className="row action-bar">
                    <ReCaptchaComponent />
                    <div className="col button-container">
                      <Main
                        onButtonClick={this.handleSubmit}
                        text="Envoyer"
                        spinner={this.state.loading}
                        disabled={this.state.loading || this.state.error !== ''}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Container>
      </GoogleReCaptchaProvider>
    );
  }
}

export default ContactForm;
