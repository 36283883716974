import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ResourceBtn from '../buttons/resource';
import PaymentLink from '../buttons/payment-link';
import { Card } from 'react-bootstrap';
import { useRouter } from 'next/router';
import { getResourceSlug } from '../../tools/resources';
import ImpossibleAction from '../shared/impossibleAction';
import SubscriptionRequest from '../shared/subscriptionRequest';
import { subscribe, subscriptionRequest } from '../../queries/backendQueries';
import { UserContext } from '../../pages/_app';
import { isSubscribed, getImageUrl } from '../../tools/utils';
import { NewspaperIcon, ChatAlt2Icon, ChartBarIcon } from '@heroicons/react/outline';
import PaymentModal from '../payment/paymentModal';

const StyleCard = styled(Card)`
  max-width: 263px;
  width: 100%;
  min-height: 454px;
  margin: 20px 10px;
  border-radius: 20px;
  box-shadow: 0 5px 16px 0 rgba(33, 37, 41, 0.1);
  background-color: #ffffff;
  border: none;
  cursor: pointer;

  .imgPreview {
    max-width: 263px;
    height: 160px;
    object-fit: cover;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .contain {
    object-fit: contain;
  }

  .articleTitle {
    margin: 24px 0;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
    color: #4e5fb7;
  }

  .body {
    position: relative;
  }

  .card-body {
    border-top: 4px solid #c7d46a;
  }

  .icon {
    width: 20px;
    height: 20px;
    position: absolute;
    color: #586118;
    top: -13px;
    left: 33px;
    z-index: 2;
  }

  .bg {
    width: 39px;
    height: 42px;
    position: absolute;
    top: -25px;
    left: 24px;
    z-index: 1;
  }

  .description {
    margin: 24px 0;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: left;
    color: #5d6267;
  }

  .btnContainer {
    display: flex;
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 1.25rem;
    margin-bottom: 15px;
  }

  .more {
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #5d6267;
    margin-right: 37px;
  }

  .gradient {
    background-image: linear-gradient(to bottom, #4e5fb7, #3d4da0);
  }

  p:after {
    content: '';
    display: inline-block;
    width: 0px;
  }

  p {
    margin-bottom: 0px;
  }
`;

const Resource = ({ resource, handleAction }) => {
  const router = useRouter();
  const { user, updateUser } = useContext(UserContext);

  const [state, setState] = useState({
    subscribed: false,
    modalShow: false,
    subShow: false,
    responseStatus: '',
    isConnected: false,
    loading: false
  });

  const [imgUrl, setImgUrl] = useState(null);
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  getImageUrl(resource.previewPicture).then((result) => setImgUrl(result));

  useEffect(() => {
    setState({ ...state, subscribed: isSubscribed(user, resource) });
  }, [user]);

  function handleClose(event) {
    if (event) event.stopPropagation();
    setState({ ...state, modalShow: false, subShow: false });
  }

  function handlePayment() {
    if (!user) {
      setState({ ...state, modalShow: true });
      return;
    }
    if (user.resourceLicences?.filter((el) => el.code === resource.code).length > 0) {
      router.push({
        pathname: `/${resource.resourceType.toLowerCase()}/${getResourceSlug(resource)}`
      });
    } else setShowPaymentModal(true);
  }

  function doSubscription() {
    setState({ ...state, loading: true });
    subscribe(user.idSso, resource.idChannelPartner)
      .then(() => {
        updateUser();
        setState({ ...state, loading: false });
        router.push({
          pathname: '/channel/[slug]',
          query: { slug: getResourceSlug(resource) }
        });
      })
      .catch((err) => {
        console.log(err);
        setState({ ...state, loading: false });
      });
  }

  function handleSubscription(event) {
    event.stopPropagation();
    if (!user) {
      setState({ ...state, modalShow: true });
      return;
    }
    if (user.userStatus === 'CREATED') {
      setState({ ...state, isConnected: true, modalShow: true });
      return;
    }
    if (!state.subscribed && user.idSso) {
      if (resource.channelStatus === 'PUBLIC') {
        doSubscription();
      } else if (resource.channelStatus === 'PRIVE') {
        subscriptionRequest(user.idSso, resource.idResource)
          .then((response) => {
            if (response.status === 'ACCEPTE') {
              doSubscription();
            } else setState({ ...state, subShow: true, responseStatus: response.status });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      router.push({
        pathname: '/channel/[slug]',
        query: { slug: getResourceSlug(resource) }
      });
    }
  }

  return (
    <>
      <StyleCard
        onClick={(e) => {
          console.log(resource);
          e.stopPropagation();
          if (resource.url !== undefined && resource.url !== null && resource.url !== '')
            window.open(`${resource.url}`, '_blank');
          else if (resource.resourceType === 'CHANNEL') handleSubscription(e);
          else if (resource.resourceType === 'INTERNE') handleAction(resource.id);
          else if (resource.paymentLink && resource.paymentLink !== null) handlePayment();
          else
            router.push({
              pathname: `/${resource.resourceType.toLowerCase()}/${getResourceSlug(resource)}`
            });
        }}
      >
        <Card.Img
          className={`imgPreview${resource.resourceType === 'service' ? ' contain' : ''}`}
          variant="top"
          src={imgUrl}
        />
        {resource.resourceType === 'CHANNEL' && resource.logo ? (
          <Card.ImgOverlay>
            <Card.Text style={{ textAlign: 'right' }}>
              <img src={resource.logo} style={{ width: '30px', height: '30px' }} />
            </Card.Text>
          </Card.ImgOverlay>
        ) : (
          ''
        )}
        <Card.Body className="body">
          {resource.resourceType === 'CHANNEL' ? (
            <ChatAlt2Icon className="icon" />
          ) : resource.resourceType === 'ARTICLE' ? (
            <NewspaperIcon className="icon" />
          ) : (
            <ChartBarIcon className="icon" />
          )}
          <img className="bg" src="/icons/bg-icon.svg" alt="bg" />
          <Card.Title className="articleTitle">{resource.title}</Card.Title>
          {resource.subtitle ? <Card.Title className="articleTitle">{resource.subtitle}</Card.Title> : ''}
          <Card.Text className="description display-linebreak">
            {resource.shortDescription
              ? resource.shortDescription.split('\\n').map((str) => {
                  return str + '\n';
                })
              : ''}
          </Card.Text>
          <div className="btnContainer">
            {resource.paymentLink ? (
              <PaymentLink onClick={handlePayment} />
            ) : (
              <ResourceBtn resource={{ ...resource, subscribed: state.subscribed }} />
            )}
          </div>
        </Card.Body>
      </StyleCard>
      <ImpossibleAction show={state.modalShow} handleClose={handleClose} isConnected={state.isConnected} />
      <SubscriptionRequest show={state.subShow} handleClose={handleClose} status={state.responseStatus} />
      <PaymentModal
        show={showPaymentModal}
        handleClose={() => setShowPaymentModal(false)}
        link={resource.paymentLink}
      />
    </>
  );
};

Resource.propTypes = {
  resource: PropTypes.object,
  handleAction: PropTypes.func
};

export default Resource;
