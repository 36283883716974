import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { getResourceSlug } from '../../tools/resources';

const ResourceContainer = styled.div`
  margin: 0 5px 0 0;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  cursor: pointer;

  a {
    color: #4e5fb7;
  }

  &::after {
    content: url('/icons/icon-connect-right-violet.svg');
    margin-left: 4px;
    vertical-align: text-top;
  }

  &:hover::after {
    content: url('/icons/icon-connect-right.svg');
  }

  a:hover,
  &:hover {
    a {
      color: #212529;
    }
  }
`;

const Resource = ({ resource }) => {
  return (
    <ResourceContainer>
      <Link
        href={
          resource.resourceType
            ? (resource.resourceType !== 'CHANNEL' || resource.subscribed) && resource.resourceType !== 'INTERNE'
              ? `/${resource.resourceType.toLowerCase()}/${getResourceSlug(resource)}`
              : '#'
            : resource.idFormation
            ? `/service/formation/${resource.slug}`
            : `/service/${resource.slug}`
        }
        onClick={(e) => e.stopPropagation()}
      >
        {resource.subscribed
          ? 'Consulter'
          : resource.resourceType === 'CHANNEL' || resource.slug === 'breeder' || resource.slug === 'agri-echange'
          ? 'Rejoindre'
          : resource.resourceType === 'SERVICE_PAC'
          ? 'Mon devis en ligne'
          : 'En savoir plus'}
      </Link>
    </ResourceContainer>
  );
};

Resource.propTypes = {
  resource: PropTypes.object
};

export default Resource;
